import React, { type FC } from "react";

import { Icon, Image } from "@Components";
import type { Colors, IncrementSizes } from "@Tokens";
import type { PromoCampaignFooterRight } from "./types";

export const MultiPromotionTileFooterRight: FC<PromoCampaignFooterRight> = ({
	color,
	icon,
	image,
	size,
	disableShadow,
}) => (
	<div
		sx={{
			flexShrink: 0,
			minWidth: "0",
			filter: disableShadow
				? undefined
				: "drop-shadow(0 0 5px rgba(0, 0, 0, 0.5))",
		}}
	>
		{icon && (
			<Icon
				name={icon}
				size={(size as keyof IncrementSizes) ?? "24"}
				color={(color as keyof Colors) ?? "backgroundWhite"}
			/>
		)}
		{image?.url && (
			<Image
				src={image.url}
				alt={image.description}
				width={Number(size) || 100}
				fluid={false}
				lazy={false}
			/>
		)}
	</div>
);
