import type React from "react";
import type { FC } from "react";

import { DontHydrate } from "@AuxiliaryComponents";
import { Icon, Label } from "@Components";
import type { Colors, Icons } from "@Tokens";
import type { PromoCampaignFooterLeft } from "./types";

const FooterLeft: FC<PromoCampaignFooterLeft> = ({
	icon,
	color,
	text,
	disableShadow,
}) => (
	<DontHydrate
		sx={{
			color: color ?? "white",
			display: "flex",
			alignItems: "center",
		}}
	>
		{icon && (
			<Icon
				name={icon}
				size="24"
				color={color as keyof Colors | undefined}
				sx={{
					filter: disableShadow
						? undefined
						: "drop-shadow(0 0 5px rgba(0, 0, 0, 1))",
				}}
			/>
		)}

		<Label
			variant="mediumbold"
			sx={{
				overflow: "hidden",
				maxHeight: "2.4em",
				lineHeight: "1.2em",
				paddingX: "3xs",
				...(!disableShadow && { textShadow: "0px 0px 5px black" }),
			}}
		>
			{text}
		</Label>
	</DontHydrate>
);

export const MultiPromotionTileFooterLeft: React.FC<
	PromoCampaignFooterLeft
> = ({ icon, color, text, disableShadow }) => {
	if (!text) {
		return <span />;
	}

	return (
		<DontHydrate
			sx={{
				flexShrink: 0,
				minWidth: "0",
				maxWidth: "60%",
			}}
		>
			<FooterLeft
				icon={icon as Icons}
				color={color as keyof Colors}
				text={text}
				disableShadow={disableShadow}
			/>
		</DontHydrate>
	);
};
