import type React from "react";

import { DontHydrate } from "@AuxiliaryComponents";
import {
	type ComponentProps,
	Image,
	type ImageProps,
	type ImageType,
} from "@Components";
import { loveholidaysTheme } from "@Themes";

const DEFAULT_WIDTHS: [number, number, number] = [400, 810, 1920];

const breakpoints = [DEFAULT_WIDTHS[0]].concat(
	loveholidaysTheme.breakpoints.map((b) => Number.parseInt(b, 10)),
);

interface HeroImageProps extends ComponentProps {
	image: ImageType;
	/**
	 * Intended height of the HeroImage
	 * Used as the amount of vertical space the hero image should take up.
	 * If the height is fluid (eg depends on campaign text), then give an estimate.
	 * Used indirectly to get a suitable crop height.
	 */
	height?: [number, number, number];
	widths?: [number, number, number];
	preferredCrop?: "left-right" | "top-bottom";
	fit?: ImageProps["fit"];
	lazy?: boolean;
	dpr?: number;
	quality?: number;
}

export const HeroImage: React.FC<HeroImageProps> = ({
	image,
	className,
	height = [200, 300, 420],
	widths = DEFAULT_WIDTHS,
	preferredCrop = "left-right",
	fit = "crop",
	lazy = false,
	dpr = 0.75,
	quality = 80,
}) => (
	<DontHydrate
		as="section"
		className={className}
		sx={{
			height,
			img: {
				width: "100%",
				height: "100%",
				objectFit: "cover",
				maxWidth: "none",
				maxHeight: "none",
			},
		}}
	>
		<Image
			src={image.url}
			alt={image.description}
			lazy={lazy}
			fluid={false}
			height={
				fit === "crop" && preferredCrop === "top-bottom"
					? [
							// Increase height of the image so that when at or just above the breakpoint the image
							// doesn't get cropped on the left and right sides. Do this by calculating the aspect
							// ratio of the image at the breakpoint.
							Math.floor((widths[0] / breakpoints[0]) * height[0]),
							Math.floor((widths[1] / breakpoints[1]) * height[1]),
							Math.floor((widths[2] / breakpoints[2]) * height[2] * dpr),
						]
					: [height[0], height[1], Math.floor(height[2] * dpr)]
			}
			width={[widths[0], widths[1], widths[2] * dpr]}
			fit={fit}
			quality={quality}
			dpr={1.5}
		/>
	</DontHydrate>
);
